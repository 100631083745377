import React from 'react'
import Img from 'gatsby-image'
import Markdown from 'react-markdown'
import { css } from '@emotion/core'
import Container from './container'
import SEO from './seo'
import Layout from './layout'
import Link from './link'
import SimpleKeywords from './simple-keywords'
import SimpleCategory from './simple-cat'
import { bpMaxSM } from '~lib/breakpoints'
import theme from '../../config/theme'

function Blog({ data: { allMdx }, pageContext: { pagination } }) {
  const { pageNum, page, nextPagePath, previousPagePath, pageCount } = pagination

  const posts = page
    .map(id =>
      allMdx.edges.find(
        edge => edge.node.id === id && edge.node.parent.sourceInstanceName !== 'pages',
      ),
    )
    .filter(post => post !== undefined)

  return (
    <Layout headerColor={theme.colors.white} >
      <SEO />
      <Container
        noVerticalPadding
        css={css`
          margin-top: 10px;
          a,
          p {
          }
          h2 {
            a {
              color: inherit;
            }
          }
          small {
            display: block;
          }
        `}
      >
        <div
          css={{
            marginTop: 20,
            fontStyle: 'italic',
          }}
        >
          {`找不到你想要的内容? `}
          <Link to="/blogs">全部内容归档</Link> / <Link to="/search">搜索文章内容</Link> /{' '}
          <Link to="/readings">我最近的阅读</Link>
        </div>
        {posts.map(({ node: post }) => {
          return (
            <div
              key={post.id}
              css={css`
              // :not(:first-of-type) {
              //   margin-top: 20px;
              //   ${bpMaxSM} {
              //     margin-top: 20px;
              //   }
              // }
              // :first-of-type {
              //   margin-top: 20px;
              //   ${bpMaxSM} {
              //     margin-top: 20px;
              //   }
              // }
              .gatsby-image-wrapper {
              }
              background: var(--bgcolor-post);
              padding: 20px 20px 30px 20px;
              ${bpMaxSM} {
                padding: 20px;
              }
              display: flex;
              flex-direction: column;
              border-bottom: 1px solid var(--color-line);
            `}
            >
              {post.frontmatter.banner && (
                <div
                  css={css`
                    margin-bottom: 20px;
                    ${bpMaxSM} {
                      padding: 20px;
                    }
                  `}
                >
                  <Link aria-label={`View ${post.frontmatter.title} article`} to={post.fields.slug}>
                    <Img fluid={post.frontmatter.banner.childImageSharp.fluid} />
                  </Link>
                </div>
              )}
              <h2
                css={css`
                  margin-top: 10px;
                  margin-bottom: 10px;
                `}
              >
                <Link
                  aria-label={`View ${post.frontmatter.title} article`}
                  to={post.fields.slug}
                  css={css`
                    border-bottom: 2px solid transparent;
                    :hover {
                      border-color: inherit;
                    }
                  `}
                >
                  {post.frontmatter.title}
                </Link>
              </h2>
              <div
                css={css`
                  font-size: 14px;
                  color: #8e8e8e;
                  margin-bottom: 10px;
                  display: flex;
                `}
              >
                <div>{post.frontmatter.date}</div>
                <SimpleCategory categories={post.frontmatter.categories} />
                <SimpleKeywords keywords={post.frontmatter.keywords} />
              </div>
              <div
                css={css`
                  margin-top: 10px;
                  overflow-x: auto;
                  p:last-child {
                    margin-bottom: 0px;
                  }
                `}
              >
                {post.fields.description ? (
                  <Markdown>{post.fields.description}</Markdown>
                ) : (
                    post.excerpt
                  )}{' '}
                {/* {post.excerpt} */}
              </div>
              {/* <Link
              to={post.fields.slug}
              aria-label={`view "${post.frontmatter.title}" article`}
            >
              查看 →
            </Link> */}
            </div>
          )
        })}
        <br />
        <br />
        <div css={{ display: 'flex', justifyContent: 'space-around' }}>
          {previousPagePath ? (
            <Link to={previousPagePath} aria-label="View previous page">
              上一页
            </Link>
          ) : (
              <div />
            )}
          {`${pageNum} / ${pageCount}`}
          {nextPagePath ? (
            <Link to={nextPagePath} aria-label="View next page">
              下一页
            </Link>
          ) : (
              <div />
            )}
        </div>
      </Container>
    </Layout>
  )
}

export default Blog
