module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/blog your pathPrefix should be "blog"
  siteTitle: `Ubug's playground`, // Navigation and Site Title
  siteTitleAlt: 'Ubug 的个人博客网站', // Alternative Site title for SEO
  siteTitleShort: 'ubug', // short_name for manifest
  siteUrl: 'https://ubug.io', // Domain of your site. No trailing slash!
  siteLanguage: 'zh-cn', // Language Tag on <html> element
  siteKeywords: ['blog', 'ubug', 'tech blog', '技术博客', 'playground'],
  siteLogo: 'favicons/android-chrome-192x192.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription:
    `Ubug's Blog, 技术博客，分享技术背后的思考~~`,
  minibio: `
    <strong>Ubug</strong> 我是 Ubug，一名开发工程师，用高质量的技术和代码改变世界。
  `,
  author: 'Ubug', // Author for schemaORGJSONLD
  organization: 'Ubug', //  Tech LLC

  // siteFBAppID: 'xxx', // Facebook App ID - Optional
  userTwitter: '', // @ubugTwitter xxx
  ogSiteName: 'Ubug', // Facebook Site Name
  ogLanguage: 'zh_CN',
  googleAnalyticsID: '',

  // Manifest and Progress color
  themeColor: '#4147DC',
  backgroundColor: '#231C42',

  // Social component
  twitter: '', // https://twitter.com/xxx/
  twitterHandle: '', // @xxx
  github: '', // https://github.com/xxx/
  linkedin: '', // https://www.linkedin.com/in/xxx/
  youtube: '', // https://www.youtube.com/channel/xxx
}
