import { css } from '@emotion/core'
import theme from '../../config/theme'
import typography from '~lib/typography'
import webfont from './webfont'

// font-family: "Noto Sans SC", "Helvetica Neue", "Helvetica", Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei","WenQuanYi Micro Hei", sans-serif;

const reset = css`
  form {
    margin: 0;
  }
  ul,
  ol {
    list-style-position: inside;
    margin-left: 0;
    font-size: ${typography.baseFontSize};
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  html,
  body {
    font-family: 
      // System Font            // https://www.webkit.org/blog/3709/using-the-system-font-in-web-content/
      -apple-system,            // OSX ^10.11 & iOS ^9  San Francisco & 苹方

      // English First
      "Helvetica Neue",         // OSX
      "Arial",                  // Win "Helvetica"
      //" Segoe UI",            // Win ^8

      // Chinese Fallback
      "PingFang SC",            // OSX ^10.11 & iOS ^9  苹方（华康信凭黑）
      "Hiragino Sans GB",       // OSX ^10.6            冬青黑体
      "STHeiti",                // OSX <10.6  & iOS <9  华文黑体
      "Microsoft YaHei",        // Win                  微软雅黑
      "Microsoft JhengHei",     // Win                  微软正黑
      "Source Han Sans SC",     // SourceHan - begin    思源黑体
      "Noto Sans CJK SC",
      "Source Han Sans CN",
      "Noto Sans SC",
      "Source Han Sans TC",
      "Noto Sans CJK TC",       // SourceHan - end
      "WenQuanYi Micro Hei",    // Linux                文泉驿微米黑
      SimSun,                   // Win old              中易宋体
      sans-serif;               // System Fallback

    font-size: 17px;
    font-style: normal;
    padding: 0;
    margin: 0;
  }
  html {
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    overflow-y: auto !important;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    //scroll-behavior: smooth;
  }
  body {
    color: var(--text-color-body);
    background-color: var(--bgcolor-body);
  }
  ::selection {
    background-color: var(--bgcolor-selection);
    color: var(--text-color-selection);
  }
  a {
    color: var(--color-anchor);
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    &:hover,
    &:focus {
      color: var(--color-anchor-hover);
    }
  }
  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
    }
    &:focus {
      outline: 0;
    }
  }
  blockquote {
    border-left: 5px solid var(--color-anchor);
    padding-left: 1rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    opacity: .8;
    font-size: 18px;
    p {
      line-height: 1.3 !important;
    }
    font-family: "PT Sans", "STKaiti", "Kaiti", "cwTeXKai", "Microsoft YaHei", sans-serif;
  }
  [tabindex='-1']:focus {
    outline: none !important;
  }
  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    white-space: pre;
  }
  pre,
  code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
      monospace;
  }
  figure {
    margin: 0 0 1rem 0;
  }
  img {
    vertical-align: middle;
  }
  [role='button'] {
    cursor: pointer;
  }
  a,
  area,
  button,
  [role='button'],
  input,
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }
  table {
    border-collapse: collapse;
    background-color: var(--bgcolor-body);
  }
  .mdx-wrapper>table{
    margin: 0 auto 1.2rem;
  }
  .mdx-wrapper>table th, .mdx-wrapper>table td {
    padding: 10px 5px;
  }
  .mdx-wrapper>table td:first-of-type, .mdx-wrapper>table th:first-of-type {
    padding-left: 20px;
  }
  .mdx-wrapper>table td:last-of-type, .mdx-wrapper>table th:last-of-type {
    padding-right: 20px;
  }
  .mdx-wrapper>table th {
    color: var(--text-color-head);
  }
  caption {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: ${theme.colors.body_color};
    text-align: center;
    caption-side: bottom;
  }
  th {
    text-align: left;
  }
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  input,
  button,
  select,
  textarea {
    line-height: inherit;
  }
  input[type='date'],
  input[type='time'],
  input[type='datetime-local'],
  input[type='month'] {
    -webkit-appearance: listbox;
  }
  textarea {
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
  }
  input[type='search'] {
    -webkit-appearance: none;
  }
  output {
    display: inline-block;
  }
  svg:not(:root) {
    overflow: hidden;
    vertical-align: middle;
  }
  [hidden] {
    display: none !important;
  }
  li > div {
    display: inline;
  }
  ${webfont}
`

export default reset
