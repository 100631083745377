import React from 'react'
import {css} from '@emotion/core'

export default function SimpleKeywords({keywords}) {
  return keywords ? (
    <span
      css={css`
        margin-left: 10px;
      `}
    >
      {keywords.map(k => {
        const text = k.replace(/(^\s)|(\s$)/, '')
        return (
          <a
            css={css`
              margin-right: 3px;
              color: #8e8e8e;
            `}
            key={k}
            href={`/keywords/#${encodeURIComponent(text)}`}
          >
            #{text}
          </a>
        )
      })}
    </span>
  ) : null
}
