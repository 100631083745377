import {useEffect, useState, useCallback} from 'react'

const authOptions = {
  useMasterKey: true,
  fetchWhenSave: true,
}

const conv = r => ({
  id: r.id,
  createdAt: r.createdAt,
  updatedAt: r.updatedAt,
  ...r.attributes,
})

export const LC_KEYS = {
  'X-LC-Id': 'lAmGU3KrKL17OlruaSSK6iqN-gzGzoHsz',
  'X-LC-Key': 'tYnf8wUGQBVnhergFmzvIvuC', // readonly
}

export function useAuth() {
  let [authed, setAuthed] = useState(false)

  const doAuth = useCallback(
    key => {
      localStorage.setItem('lean_cloud_masterkey', key)

      import('leancloud-storage').then(AV => {
        AV.init({
          appId: LC_KEYS['X-LC-Id'],
          appKey: LC_KEYS['X-LC-Key'],
          masterKey: key,
          serverURL: 'https://api-leancloud.ubug.io',
        })
        setAuthed(true)
      })
    },
    [authed],
  )

  const quitAuth = useCallback(
    key => {
      localStorage.removeItem('lean_cloud_masterkey')

      import('leancloud-storage').then(AV => {
        AV.init({
          appId: LC_KEYS['X-LC-Id'],
          appKey: LC_KEYS['X-LC-Key'],
          masterKey: undefined,
          serverURL: 'https://api-leancloud.ubug.io',
        })
        setAuthed(false)
      })
    },
    [authed],
  )

  useEffect(() => {
    const mk = localStorage.getItem('lean_cloud_masterkey')
    if (mk && mk != '') {
      doAuth(mk)
    }
  }, [])

  return [authed, doAuth, quitAuth]
}

export function useLeanCloudDao(
  className,
  descendings = [],
  page = 1,
  limit = 20,
  includes = [],
  queryDealer,
) {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [refreshC, setRefresh] = useState(0)
  useEffect(() => {
    setIsLoading(true)
    import('leancloud-storage').then(AV => {
      const query = new AV.Query(className)
      if (!descendings || descendings.length == 0) query.descending('createdAt')
      descendings.forEach(c => {
        query.addDescending(c)
      })
      if (queryDealer) {
        queryDealer(query, AV)
      }
      query.skip((page - 1) * limit)
      query.limit(limit)
      if (includes.length > 0) query.include(includes)
      query.find(authOptions).then(result => {
        setData(data => {
          data = []
          return [...result.map(r => ({...conv(r)}))]
        })
        setIsLoading(false)
      })
    })
  }, [className, page, limit, refreshC])

  const update = useCallback(
    async (plain, attrs) => {
      const AV = await import('leancloud-storage')
      const ref = AV.Object.createWithoutData(className, plain.id)
      const item = await ref.save(attrs, authOptions)
      // console.log(`保存成功。objectId：${item.id}`);
      setData(data => {
        const ind = data.findIndex(v => v.id == item.id)
        return [...data.slice(0, ind), {...data[ind], ...conv(item)}, ...data.slice(ind + 1)]
      })
    },
    [data],
  )

  const del = useCallback(
    async plain => {
      const AV = await import('leancloud-storage')
      const ref = AV.Object.createWithoutData(className, plain.id)
      const item = ref.destroy(authOptions)
      // console.log(`删除成功。objectId：${item.id}`);
      setData(data => {
        const ind = data.findIndex(v => v.id == plain.id)
        return [...data.slice(0, ind), ...data.slice(ind + 1)]
      })
    },
    [data],
  )

  const create = useCallback(
    async attrs => {
      const AV = await import('leancloud-storage')
      const ref = new AV.Object(className)
      const item = await ref.save(attrs, authOptions)
      // console.log(`保存成功。objectId：${item.id}`);
      setData(data => {
        return [conv(item), ...data.slice(0)]
      })
    },
    [data],
  )

  const refresh = useCallback(() => {
    setRefresh(refreshC + 1)
  }, [refreshC])

  return [data, update, del, create, refresh, isLoading]
}

export async function fetchRestApi(api) {
  const r = await fetch(api, {
    method: 'get',
    headers: {
      ...LC_KEYS,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  })
  return await r.json()
}

export async function updateOrCreateRestApi(api, objectId, body) {
  const r = await fetch(`${api}${objectId ? '/' + objectId : ''}`, {
    method: objectId ? 'put' : 'post',
    headers: {
      ...LC_KEYS,
      'Content-Type': 'application/json',
    },
    body,
    mode: 'cors',
  })
  return await r.json()
}

const blog_counterUrl = 'https://api-leancloud.ubug.io/1.1/classes/blog_counter'
export async function collectView(attr = 'views') {
  const pathName = window.location.pathname.replace(/\/$/, '')
  const response = await fetchRestApi(
    `${blog_counterUrl}?where=${JSON.stringify({
      blog: pathName,
    })}`,
  )
  let objectId
  let num = 1
  if (response.results && response.results.length !== 0) {
    objectId = response.results[0].objectId
    num = (response.results[0][attr] || 0) + 1
  }
  const res = await updateOrCreateRestApi(
    blog_counterUrl,
    objectId,
    JSON.stringify({
      [attr]: num,
      blog: pathName,
    }),
  )
  console.log(`--> ${pathName} 已统计`, res)
  return res
}
