import React, { useState, useEffect } from 'react'
import BottomHandler from './bottomHandler'

export default function DarkTheme() {
  const [useDarkMode, setUseDarkMode] = useState(false)

  useEffect(() => {
    document.querySelector('html').dataset.theme = useDarkMode ? 'dark' : 'light'
    if (window.monaco) window.monaco.editor.setTheme(useDarkMode ? 'vs-dark' : 'vs-light')
  }, [useDarkMode])

  useEffect(() => {
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
    if (prefersDarkScheme.matches) setUseDarkMode(true)
  }, [])

  return (
    <i />
    // <BottomHandler
    //   title="Dark"
    //   id="dark-toggle"
    //   trigger={() => {
    //     setUseDarkMode(s => !s)
    //   }}
    //   isSmallView={true}
    //   color="#454545"
    //   bottom={80}
    // />
  )
}
