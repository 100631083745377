import React from 'react'
import Link from './link'
import { css } from '@emotion/core'
import theme from '../../config/theme'
import { bpMaxSM } from '~lib/breakpoints'
import Container from './container'
import GradientBg from './hoverGradientBg'

import Signature from '../images/signature.png'

import { collectView } from '~lib/leancloud'

export default class Footer extends React.Component {
  componentDidMount() {
    collectView()
  }
  render() {
    return (
      <footer
        css={css`
          background: ${theme.colors.purple_dark};
          color: white;
          margin-top: 130px;
        `}
      >
        <Container
          css={css`
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            ${bpMaxSM} {
              padding-top: 0;
              flex-direction: column;
            }
          `}
        >
          {
            <div css={{ marginTop: -120 }}>
              <GradientBg
                style={{
                  maxWidth: '350px',
                  padding: '40px',
                  borderRadius: '5px',
                }}
              >
                <h3
                  css={css`
                    margin-bottom: 10px;
                    margin-top: 0;
                    color: white;
                  `}
                >
                  🐞 关于
                </h3>
                <p>有时间就会分享一些技术文章、专业内容、经典问题、系列功能等。</p>
                <div style={{ fontSize: 13 }}>｛⛔ 未标注内容均为原创，请勿转载 ©️｝</div>
              </GradientBg>
            </div>
          }
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              div,
              img {
                margin: 50px 0;
                ${bpMaxSM} {
                  margin: 20px 0;
                }
              }
              ${bpMaxSM} {
                align-items: center;
              }
            `}
          >
            <Link to="https://uflow.ubug.io/" aria-label="自留地" title="自留地">
              <img
                src={Signature}
                alt="Ubug"
                css={css`
                  max-width: 100px;
                `}
              />
            </Link>
            <div style={{ fontSize: 14 }}>
              <a
                style={{ color: 'rgb(50, 124, 220)', fontSize: 14 }}
                href="https://beian.miit.gov.cn/"
                rel="noopener noreferrer"
                target="_blank"
              >
                🚓 皖ICP备17008320号-1
              </a>
            </div>
          </div>
        </Container>
      </footer>
    )
  }
}
