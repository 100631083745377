import React, { useState } from 'react'
import { css } from '@emotion/core'
import theme from '../../config/theme'
import Container from './container'
import { Link } from 'gatsby'

const Toggle = ({ color = 'white' }) => {
  const [isToggledOn, setToggle] = useState(false)
  const toggle = () => {
    if (isToggledOn) {
      const ele = document.querySelector('#nav-mobile')
      ele.classList.remove('show')
      setTimeout(() => {
        setToggle(false)
      }, 300);
    } else {
      setToggle(true)
      const detectHasCover = () => {
        const ele = document.querySelector('#nav-mobile')
        if (!ele) {
          window.requestAnimationFrame(detectHasCover)
        } else {
          window.requestAnimationFrame(() => {
            setTimeout(() => {
              ele.classList.add('show')
            }, 0);
          });
        }
      }
      window.requestAnimationFrame(detectHasCover);
    }
  }
  return (
    <div className="mobile-nav">
      <button
        onClick={toggle}
        aria-label={`${isToggledOn ? 'close menu' : 'open menu'}`}
        css={css`
          z-index: 30;
          top: -5px;
          position: relative;
          background: transparent;
          border: none;
          :hover:not(.touch),
          :focus {
            background: transparent;
            border: none;
            outline: none;
          }
        `}
      >
        <div
          css={css`
            width: 24px;
            height: 2px;
            background: ${color};
            position: absolute;
            left: 0;
            ${isToggledOn ? 'background: transparent' : `background: ${color}`};
            transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1);
            ::before {
              content: '';
              top: -8px;
              width: 24px;
              height: 2px;
              background: ${isToggledOn ? 'white' : `${color}`};
              position: absolute;
              left: 0;
              ${isToggledOn ? 'transform: rotate(45deg); top: 0; ' : 'transform: rotate(0)'};
              transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1);
            }
            ::after {
              top: 8px;
              content: '';
              width: 24px;
              height: 2px;
              background: ${isToggledOn ? 'white' : `${color}`};
              position: absolute;
              left: 0;
              ${isToggledOn ? 'transform: rotate(-45deg); top: 0;' : 'transform: rotate(0)'};
              transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1);
            }
          `}
        />
      </button>
      {isToggledOn && (
        <div
          id="nav-mobile"
        >
          <Container
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-evenly;
              a {
                color: white;
                font-size: 22px;
                margin: 10px 0;
                padding: 10px;
                border-radius: 5px;
                :hover {
                  background: rgba(0, 0, 0, 0.2);
                }
              }
              .active {
                background: rgba(0, 0, 0, 0.2);
              }
            `}
          >
            <Link aria-label="View blog page" to="/blogs" activeClassName="active">
              📃 文章
            </Link>
            <Link aria-label="View talks page" to="/talks" activeClassName="active">
              💬 闲说
            </Link>
            <Link aria-label="View studio page" to="/studio" activeClassName="active">
              🚧 工作间
            </Link>
            <Link aria-label="View slides page" to="/slides" activeClassName="active">
              👨‍💻 演示
            </Link>
            <Link aria-label="View about page" to="/about" activeClassName="active">
              👍 关于我
            </Link>
          </Container>
        </div>
      )}
    </div>
  )
}

export default Toggle
