import React, { useState, useEffect, useRef } from 'react'
import { css } from '@emotion/core'
import theme from '../../config/theme'
import { bpMaxMD, bpMaxSM } from '~lib/breakpoints'

function GradientBg({ children, style }) {
  const secRef = useRef(null)
  useEffect(() => {
    var handler = e => {
      secRef.current.style.setProperty('--x', `${e.pageX - secRef.current.offsetLeft}px`)
      secRef.current.style.setProperty('--y', `${e.pageY - secRef.current.offsetTop}px`)
    }
    document.body.addEventListener('mousemove', handler, false)
    return () => {
      document.body.removeEventListener('mousemove', handler)
    }
  }, [])

  return (
    <section
      ref={secRef}
      style={{ '--x': 0, '--y': 0, ...(style || {}) }}
      css={css`
        * {
          color: ${theme.colors.white};
        }
        width: 100%;
        background: #3155dc;
        background-image: linear-gradient(213deg, #854bf1 0%, #4335df 100%),
          linear-gradient(32deg, rgba(255, 255, 255, 0.25) 33%, rgba(153, 153, 153, 0.25) 100%);
        background-position: center right, center left;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 0;
        position: relative;
        align-items: center;
        // display: flex;
        padding-top: 40px;
        overflow: hidden;
        ::before {
          --size: 800px;
          position: absolute;
          z-index: -1;
          left: 0;
          top: 0;
          width: var(--size);
          height: var(--size);
          background-image: radial-gradient(circle closest-side, #844af0, transparent);
          content: '';
          transform: translate3d(
            calc(var(--x) - var(--size) / 2),
            calc(var(--y) - var(--size) / 2),
            0
          );
        }

        ${bpMaxMD} {
          background-size: cover;
        }
        ${bpMaxSM} {
          padding-top: 60px;
        }
      `}
    >
      {children}
      {/* <div style={{ poisition: 'relative', zIndex: 1 }}></div> */}
    </section>
  )
}

export default GradientBg
