import React from 'react'
import {css} from '@emotion/core'

export default function SimpleCategory({categories}) {
  return (
    <span
      css={css`
        color: #3f51b5;
        margin-left: 10px;
      `}
    >
      <a
        href={`/categories/#${encodeURIComponent(categories)}`}
        css={css`
          color: #8e8e8e;
        `}
      >
        {`@${categories}`}
      </a>
    </span>
  )
}
